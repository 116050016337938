.container {
    display: flex;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    /*一開始需要在父層宣告為 flex 才有效*/
}

.left {
    flex: 1;
    position: sticky;
    max-height: 99vh;
    overflow-y: auto;
    background-color: rgb(61, 186, 73);
    /*對應子層加上*/
}

.mid {
    flex: 5;
    position: sticky;
    max-height: 99vh;
    overflow-y: auto;
    background-color: rgb(245, 225, 242);
}

.right {
    margin-left: 3px;
    flex: 2;
    position: sticky;
    max-height: 99vh;
    overflow-y: auto;
    background-color: azure;
    /*對應子層加上；1數值是內元件之間佔的比例*/
}

.top {
    flex: 1;
    height: 40vh;
    background-color: rgb(149, 209, 232);
    overflow-y: auto;
}

.bottom {
    flex: 1;
    height: 59vh;
    background-color: rgb(220, 241, 249);
    overflow-y: auto;
}